import React from 'react';
import PropTypes from 'prop-types';
import Text from '@components/core/Text';
import Img from 'gatsby-image';
import Video from '@components/Video';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Modal from '@components/core/Modal/Modal';
import Section from '@components/core/Section';
import PlaybackButton from '@components/core/PlaybackButton';
import Grid, { Column, Row } from '@components/core/Grid';
import useModal from '@hooks/useModal';
import cx from 'classnames';
import styles from './page-header.module.scss';

const renderHeaderContent = ({
    previewUrl,
    videoUrl,
    videoCms,
    fluid,
    toggleModal,
    setItemModalOpen,
    itemModalOpen,
    isSectionReel,
    titleImage,
    alt,
    children,
}) => {
    const hasVideo = !!(videoUrl || videoCms || previewUrl);
    const hasImage = !!fluid;
    const hasChildren = !!children;
    const hasModal = !!(videoUrl || videoCms);
    const imageClasses = cx(styles.image, hasModal && styles.pointer);
    const previewClasses = cx(styles.preview, hasModal && styles.pointer);

    if (hasChildren) {
        return <div className={styles.contentContainer}>{children}</div>;
    }

    if (hasVideo) {
        return (
            <>
                <div
                    tabIndex={0}
                    onClick={toggleModal}
                    onKeyDown={toggleModal}
                    role="button"
                    className={styles.contentContainerClick}
                >
                    {previewUrl && previewUrl.src ? (
                        <div className={previewClasses}>
                            <Video
                                src={previewUrl.src}
                                controls={false}
                                playing
                                muted
                                loop
                            />
                        </div>
                    ) : (
                        <>
                            <div className={imageClasses}>
                                <Img
                                    fluid={fluid}
                                    title={titleImage}
                                    alt={alt}
                                />
                            </div>
                            {(videoUrl || videoCms) && (
                                <PlaybackButton
                                    className={styles.videoButton}
                                    variant={isSectionReel ? 'reel' : 'video'}
                                    isPlaying={itemModalOpen}
                                />
                            )}
                        </>
                    )}
                </div>
                {(videoUrl || videoCms) && (
                    <Modal
                        isTransparent
                        isActive={itemModalOpen}
                        handleClose={() => setItemModalOpen(false)}
                    >
                        <Video
                            onModal
                            src={videoUrl || videoCms.src}
                            muted={false}
                            playing
                        />
                    </Modal>
                )}
            </>
        );
    }

    if (hasImage) {
        return (
            <div className={styles.contentContainer}>
                {fluid && (
                    <div className={styles.image}>
                        <Img fluid={fluid} title={titleImage} alt={alt} />
                    </div>
                )}
            </div>
        );
    }

    return null;
};

const PageHeader = ({
    infoHeader: {
        title,
        headerDescription,
        image,
        isSectionReel,
        videoCms,
        videoUrl,
        previewUrl,
    },
    children,
    containerClass,
}) => {
    const { childImageSharp: { title: titleImage, alt, fluid } = {} } =
        image || {};
    const [itemModalOpen, setItemModalOpen, toggleModal] = useModal(false);
    const { md } = useBreakpoint();
    const containerClasses = cx(styles.headerSection, containerClass);
    return (
        <Section className={containerClasses}>
            <Grid>
                <Row>
                    <Column md="6" />
                    <Column md="6">
                        <div className={styles.headerInfo}>
                            <Text
                                size="h1"
                                as="h1"
                                color="green"
                                className={styles.headerTitle}
                            >
                                {title}
                            </Text>

                            <Text as="p" size="h5">
                                {headerDescription}
                            </Text>
                        </div>
                    </Column>
                </Row>
            </Grid>
            <Grid maxWidthFull={!md}>
                {renderHeaderContent({
                    previewUrl,
                    videoUrl,
                    videoCms,
                    fluid,
                    toggleModal,
                    setItemModalOpen,
                    itemModalOpen,
                    isSectionReel,
                    titleImage,
                    alt,
                    children,
                    containerClass,
                })}
            </Grid>
        </Section>
    );
};

PageHeader.propTypes = {
    infoHeader: PropTypes.shape({
        title: PropTypes.string.isRequired,
        headerDescription: PropTypes.string.isRequired,
        additionalInfo: PropTypes.string,
        image: PropTypes.shape({
            childImageSharp: PropTypes.shape({
                title: PropTypes.string,
                alt: PropTypes.string,
                fluid: PropTypes.shape({
                    aspectRatio: PropTypes.number.isRequired,
                    src: PropTypes.string.isRequired,
                    srcSet: PropTypes.string.isRequired,
                    sizes: PropTypes.string.isRequired,
                    base64: PropTypes.string,
                    tracedSVG: PropTypes.string,
                    srcWebp: PropTypes.string,
                    srcSetWebp: PropTypes.string,
                    media: PropTypes.string,
                    maxWidth: PropTypes.number,
                    maxHeight: PropTypes.number,
                }),
            }),
        }),
        isSectionReel: PropTypes.bool,
        videoCms: PropTypes.shape({
            src: PropTypes.string,
        }),
        previewUrl: PropTypes.shape({
            src: PropTypes.string,
        }),
        videoUrl: PropTypes.string,
    }).isRequired,
    children: PropTypes.node,
    containerClass: PropTypes.string,
};

PageHeader.defaultProps = {
    children: null,
    containerClass: '',
};

export default PageHeader;
