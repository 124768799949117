import React from 'react';
import Img from 'gatsby-image';
import propTypes from 'prop-types';

const FLUID = 'fluid';
const FIXED = 'fixed';
const Image = ({ showFixed, fixed, fluid, className }) => {
    let imageToShow = FLUID;
    if ((showFixed && fixed) || (fixed && !fluid)) {
        imageToShow = FIXED;
    }
    return imageToShow === FIXED ? (
        <Img className={className} fixed={fixed} />
    ) : (
        <Img className={className} fluid={fluid} />
    );
};

Image.propTypes = {
    showFixed: propTypes.bool,
    fixed: propTypes.objectOf(propTypes.any),
    fluid: propTypes.objectOf(propTypes.any),
    className: propTypes.string,
};

Image.defaultProps = {
    showFixed: false,
    fixed: null,
    fluid: null,
    className: '',
};

export default Image;
