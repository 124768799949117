import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Text from '@components/core/Text';
import styles from './people-card.module.scss';

const PeopleCard = ({
    className,
    name,
    jobTitle,
    hasAnimatedPicture,
    picture,
}) => {
    const classes = cx(styles.card, className);
    const imageWrapperClasses = cx(
        {
            [styles.animatedPicture]: hasAnimatedPicture,
        },
        styles.pictureWrapper
    );

    const { childImageSharp = {} } = picture || {};

    const imageWrapperStyle = {
        backgroundImage: `url('${childImageSharp.fluid.src}')`,
    };

    return (
        <div className={classes}>
            <div className={imageWrapperClasses} style={imageWrapperStyle} />
            <div className={styles.info}>
                <Text as="h5" size="h5" className={styles.peopleName}>
                    {name}
                </Text>
                <Text as="p" size="paragraphSm" className={styles.peopleTitle}>
                    {jobTitle}
                </Text>
            </div>
        </div>
    );
};

PeopleCard.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    jobTitle: PropTypes.string.isRequired,
    hasAnimatedPicture: PropTypes.bool.isRequired,
    picture: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            title: PropTypes.string,
            alt: PropTypes.string,
            fluid: PropTypes.shape({
                aspectRatio: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
                maxWidth: PropTypes.number,
                maxHeight: PropTypes.number,
            }),
            fixed: PropTypes.shape({
                width: PropTypes.number.isRequired,
                height: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
            }),
        }),
    }).isRequired,
};

PeopleCard.defaultProps = {
    className: '',
};

export default PeopleCard;
