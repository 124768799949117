import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './zoom-in-effect.module.scss';

const Image = ({ children, isHovered, className }) => {
    const wrapperClassName = cx(styles.resizer, className);

    return (
        <div
            className={cx(styles.container, {
                [styles.hover]: isHovered,
            })}
        >
            <div className={styles.cropper}>
                <div className={wrapperClassName}>{children}</div>
            </div>
        </div>
    );
};

Image.defaultProps = {
    className: '',
};

Image.propTypes = {
    children: PropTypes.node.isRequired,
    isHovered: PropTypes.bool.isRequired,
    className: PropTypes.string,
};

export default Image;
