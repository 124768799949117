import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Slider from 'react-slick';
import ArrowRightIcon from '@components/Icons/slide-arrow-right-on.svg';
import ArrowLeftIcon from '@components/Icons/slide-arrow-left-on.svg';
import styles from './carousel-with-padding.module.scss';
import { BREAKPOINTS } from '../../../utils/const';

const CarouselWithPadding = ({
    children,
    infinite,
    arrows,
    isFullScreen,
    settings,
    sliderClassName,
    arrowsClassName,
}) => {
    const slider = useRef(null);
    const className = cx(
        {
            [styles.fullScreen]: isFullScreen,
        },
        sliderClassName
    );

    const arrowsClasses = cx(
        {
            [styles.arrowsContainer]: true,
        },
        arrowsClassName
    );

    const getCarouselSettings = useCallback(() => {
        return {
            arrows: false,
            infinite,
            centerMode: true,
            centerPadding: 175,
            className,
            responsive: [
                {
                    breakpoint: BREAKPOINTS.xl,
                    settings: {
                        centerPadding: 158,
                    },
                },
                {
                    breakpoint: BREAKPOINTS.l,
                    settings: {
                        centerPadding: 118,
                    },
                },
                {
                    breakpoint: BREAKPOINTS.sm,
                    settings: {
                        centerPadding: 43,
                    },
                },
                {
                    breakpoint: BREAKPOINTS.xs,
                    settings: {
                        centerPadding: 17,
                    },
                },
            ],
            ...settings,
        };
    }, [settings]);

    const handleClickNextArrow = () => {
        slider.current.slickNext();
    };

    const handleClickPrevArrow = () => {
        slider.current.slickPrev();
    };

    const shouldRenderArrows = () => {
        if (arrows) {
            return (
                <div className={arrowsClasses}>
                    <button
                        className={styles.arrowBtn}
                        type="button"
                        aria-label="Previous Slide"
                        onClick={handleClickPrevArrow}
                    >
                        <ArrowLeftIcon width={48} height={48} />
                    </button>
                    <button
                        className={styles.arrowBtn}
                        type="button"
                        aria-label="Next Slide"
                        onClick={handleClickNextArrow}
                    >
                        <ArrowRightIcon width={48} height={48} />
                    </button>
                </div>
            );
        }

        return null;
    };
    return (
        <div>
            {shouldRenderArrows()}
            <Slider ref={slider} {...getCarouselSettings()}>
                {children}
            </Slider>
        </div>
    );
};

CarouselWithPadding.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    infinite: PropTypes.bool,
    arrows: PropTypes.bool,
    isFullScreen: PropTypes.bool,
    settings: PropTypes.objectOf(PropTypes.any),
    sliderClassName: PropTypes.string,
    arrowsClassName: PropTypes.string,
};

CarouselWithPadding.defaultProps = {
    arrows: true,
    infinite: false,
    isFullScreen: false,
    sliderClassName: '',
    arrowsClassName: '',
    settings: {},
};

export default CarouselWithPadding;
