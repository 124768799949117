import React from 'react';
import PropTypes from 'prop-types';
import Grid, { Column, Row } from '@components/core/Grid';
import Section from '@components/core/Section';
import Text from '@components/core/Text';
import Markdown from 'react-remarkable';
import styles from './intro-section.module.scss';

const Intro = ({ leftParagraph, rightParagraph }) => {
    return (
        <Section className={styles.introSection}>
            <Grid>
                <Row>
                    <Column md="6" className={styles.introColumns}>
                        <Text
                            as="div"
                            size="h5"
                            className={styles.introHeading}
                        >
                            <Markdown>{leftParagraph}</Markdown>
                        </Text>
                    </Column>
                    <Column offsetMd="1" md="5" className={styles.introText}>
                        <Text as="div" size="paragraphMd">
                            <Markdown>{rightParagraph}</Markdown>
                        </Text>
                    </Column>
                </Row>
            </Grid>
        </Section>
    );
};

Intro.propTypes = {
    leftParagraph: PropTypes.string.isRequired,
    rightParagraph: PropTypes.string.isRequired,
};

export default Intro;
