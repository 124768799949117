import React from 'react';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Grid, { Column, Row } from '@components/core/Grid';
import SectionHeader from '@components/SectionHeader';
import Text from '@components/core/Text';
import Markdown from 'react-remarkable';
import ParallaxSection from '@components/core/ParallaxSection';
import styles from './certifications.module.scss';

const Certifications = ({ data }) => {
    const { sectionHeader, leftParagraph, certificationIcons } = data;
    const breakpoints = useBreakpoint();

    return (
        <ParallaxSection
            dataTheme="dark"
            className={styles.section}
            variant="dark"
            disabled={!breakpoints.md}
            bottomDisabled
        >
            <Grid>
                <Row>
                    <Column md="6">
                        <SectionHeader
                            className={styles.sectionHeader}
                            variant="dark"
                            eyebrow={sectionHeader.eyebrow}
                            title={sectionHeader.title}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column md="6">
                        <Text as="p" size="paragraphMd" color="white">
                            <Markdown>{leftParagraph}</Markdown>
                        </Text>
                    </Column>
                    <Column md="6" className={styles.rightColumn}>
                        {certificationIcons.length > 0 &&
                            certificationIcons.map(certification => (
                                <div
                                    className={
                                        styles.certificationContainerIcon
                                    }
                                >
                                    <img
                                        className={styles.certificationIcon}
                                        src={certification.icon.publicURL}
                                        alt={certification.title}
                                    />
                                </div>
                            ))}
                    </Column>
                </Row>
            </Grid>
        </ParallaxSection>
    );
};

Certifications.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
        }),
        leftParagraph: PropTypes.string,
        certificationIcons: PropTypes.arrayOf(PropTypes.any),
    }).isRequired,
};

export default Certifications;
