import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Eyebrow from '@components/core/Eyebrow';
import Text from '@components/core/Text';
import Image from '@components/core/Image';
import AnchorToSection from '@components/core/AnchorToSection';
import ZoomInImage from '@components/core/ZoomInEffect';
import styles from './article-card.module.scss';

const ArticleCardWrapper = ({
    children,
    className,
    to,
    onMouseEnter,
    onMouseLeave,
}) => {
    const Wrapper = to ? Link : 'div';
    return (
        <Wrapper
            to={to}
            className={className}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {children}
        </Wrapper>
    );
};

ArticleCardWrapper.propTypes = {
    to: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
};

ArticleCardWrapper.defaultProps = {
    className: '',
    to: '',
    onMouseEnter: () => {},
    onMouseLeave: () => {},
};

const ArticleCard = ({
    article: { title, topic, image, slug, overview },
    sizeTitle,
    colorTitle,
    colorOverview,
    sizeDescription,
    sizeEyebrow,
    layoutDistribution,
    showAnchorTitle,
    showAnchor,
    linkText,
    classCardWrapper,
    classImageWrapper,
    showFixedImg,
    classInfo,
    isFullScreen,
    eyebrowCase,
    eyebrowNormalSpacing,
    clientLogo,
}) => {
    const isLinkCard = Boolean(slug);
    const [isHovered, setIsHovered] = useState(false);
    const classes = cx(
        styles.card,
        styles[layoutDistribution] || styles.vertical,
        {
            [classCardWrapper]: !isFullScreen,
            [styles.cardFullWidth]: isFullScreen,
            [styles.cardLink]: isLinkCard,
            [styles.hover]: isHovered,
        }
    );
    const classesInfo = cx(classInfo, {
        [styles.infoHorizontal]: layoutDistribution === 'horizontal',
        [styles.info]: !showAnchorTitle,
        [styles.infoAnchorTitle]: showAnchorTitle,
    });

    const overviewClasses = cx(styles.overview);

    const titleClasses = cx(styles.title, styles[colorTitle], {
        [styles.hover]: isHovered,
        [styles.titleAnchor]: isLinkCard && showAnchorTitle,
        [styles.titleHorizontal]: layoutDistribution === 'horizontal',
    });

    const eyebrowClasses = cx(styles.eyebrow, {
        [styles.eyebrowHorizontal]: layoutDistribution === 'horizontal',
    });
    const { childImageSharp = {} } = image || {};

    return (
        <div
            className={classes}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <ArticleCardWrapper className={classImageWrapper} to={slug}>
                <ZoomInImage isHovered={isLinkCard && isHovered}>
                    <Image
                        showFixed={showFixedImg}
                        fixed={childImageSharp.fixed}
                        fluid={childImageSharp.fluid}
                    />
                </ZoomInImage>
            </ArticleCardWrapper>
            {clientLogo && clientLogo.childImageSharp && (
                <div className={styles.clientLogoWrapper}>
                    <Image fluid={clientLogo.childImageSharp.fluid} />
                </div>
            )}

            <div className={classesInfo}>
                {topic && (
                    <Eyebrow
                        className={eyebrowClasses}
                        casing={eyebrowCase}
                        size={sizeEyebrow}
                        normalSpacing={eyebrowNormalSpacing}
                    >
                        <span>{topic}</span>
                    </Eyebrow>
                )}

                <ArticleCardWrapper to={slug}>
                    {isLinkCard && showAnchorTitle ? (
                        <AnchorToSection
                            sizeIcon="4"
                            className={styles.anchorToSection}
                        >
                            <Text
                                as="h3"
                                size={sizeTitle}
                                color={colorTitle}
                                className={titleClasses}
                            >
                                {title}
                            </Text>
                        </AnchorToSection>
                    ) : (
                        <Text
                            as="h3"
                            size={sizeTitle}
                            color={colorTitle}
                            className={titleClasses}
                        >
                            {title}
                        </Text>
                    )}
                    {overview && (
                        <p className={overviewClasses}>
                            <Text
                                as="span"
                                size={sizeDescription}
                                color={colorOverview}
                            >
                                {overview}
                            </Text>
                        </p>
                    )}
                    {isLinkCard && showAnchor && (
                        <div className={styles.link}>
                            <Link to={slug}>{linkText}</Link>
                        </div>
                    )}
                </ArticleCardWrapper>
            </div>
        </div>
    );
};

ArticleCard.propTypes = {
    article: PropTypes.shape({
        title: PropTypes.string,
        image: {
            childImageSharp: PropTypes.shape({
                title: PropTypes.string,
                alt: PropTypes.string,
                fluid: PropTypes.shape({
                    aspectRatio: PropTypes.number.isRequired,
                    src: PropTypes.string.isRequired,
                    srcSet: PropTypes.string.isRequired,
                    sizes: PropTypes.string.isRequired,
                    base64: PropTypes.string,
                    tracedSVG: PropTypes.string,
                    srcWebp: PropTypes.string,
                    srcSetWebp: PropTypes.string,
                    media: PropTypes.string,
                    maxWidth: PropTypes.number,
                    maxHeight: PropTypes.number,
                }),
                fixed: PropTypes.shape({
                    width: PropTypes.number.isRequired,
                    height: PropTypes.number.isRequired,
                    src: PropTypes.string.isRequired,
                    srcSet: PropTypes.string.isRequired,
                    base64: PropTypes.string,
                    tracedSVG: PropTypes.string,
                    srcWebp: PropTypes.string,
                    srcSetWebp: PropTypes.string,
                    media: PropTypes.string,
                }),
            }),
        }.isRequired,
        topic: PropTypes.string,
        overview: PropTypes.string,
        slug: PropTypes.string,
    }).isRequired,
    sizeTitle: PropTypes.string,
    colorTitle: PropTypes.string,
    sizeDescription: PropTypes.string,
    sizeEyebrow: PropTypes.string,
    layoutDistribution: PropTypes.string,
    showAnchorTitle: PropTypes.bool,
    showAnchor: PropTypes.bool,
    colorOverview: PropTypes.string,
    linkText: PropTypes.string,
    showFixedImg: PropTypes.bool,
    classCardWrapper: PropTypes.string,
    classImageWrapper: PropTypes.string,
    classInfo: PropTypes.string,
    isFullScreen: PropTypes.bool,
    eyebrowCase: PropTypes.oneOf([
        'uppercase',
        'lowercase',
        'capitalize',
        'inherit',
    ]),
    eyebrowNormalSpacing: PropTypes.bool,
    clientLogo: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            title: PropTypes.string,
            alt: PropTypes.string,
            fluid: PropTypes.shape({
                aspectRatio: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
                maxWidth: PropTypes.number,
                maxHeight: PropTypes.number,
            }),
        }),
    }),
};

ArticleCard.defaultProps = {
    colorTitle: 'black',
    sizeTitle: 'paragraphSm',
    sizeDescription: 'paragraphSm',
    sizeEyebrow: 'paragraphXs',
    layoutDistribution: 'vertical',
    showAnchorTitle: false,
    showAnchor: false,
    colorOverview: 'light-grey',
    linkText: 'Learn more',
    showFixedImg: false,
    classCardWrapper: '',
    classImageWrapper: '',
    classInfo: '',
    isFullScreen: false,
    eyebrowCase: 'uppercase',
    eyebrowNormalSpacing: false,
    clientLogo: {},
};

export default ArticleCard;
