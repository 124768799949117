import React, { useState } from 'react';
import propTypes from 'prop-types';
import cx from 'classnames';
import Grid from '@components/core/Grid';
import styles from './tabs.module.scss';
import Tab from './Tab';

const Tabs = ({ children, className, contentClassName }) => {
    const [activeTab, setActiveTab] = useState(
        Array.isArray(children) && children[0].props.label
    );

    const classes = cx(
        {
            [styles.tabsContainer]: true,
        },
        className
    );

    const contentStyleClasses = cx(
        {
            [styles.tabContent]: true,
        },
        contentClassName
    );

    return (
        <div className={classes}>
            <Grid className={styles.tabListGrid}>
                <div className={styles.tabListWrapper}>
                    <ol className={styles.tabList}>
                        {children.map(child => {
                            const { label } = child.props;
                            return (
                                <Tab
                                    activeTab={activeTab}
                                    key={label}
                                    label={label}
                                    onClick={setActiveTab}
                                    className={styles.tabItem}
                                />
                            );
                        })}
                    </ol>
                </div>
            </Grid>
            <div className={contentStyleClasses}>
                {children.map(child => {
                    if (child.props.label !== activeTab) return undefined;
                    return child.props.children;
                })}
            </div>
        </div>
    );
};

Tabs.propTypes = {
    children: propTypes.node.isRequired,
    className: propTypes.string,
    contentClassName: propTypes.string,
};

Tabs.defaultProps = {
    className: '',
    contentClassName: '',
};

export default Tabs;
