import React from 'react';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import Section from '@components/core/Section';
import PageHeader from '@components/PageHeader';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Certifications from '@sections/AboutUs/Certifications';
import Leadership from '@sections/AboutUs/Leadership';
import PeopleCulture from '@sections/AboutUs/PeopleCulture';
import WorkingWithUs from '@sections/AboutUs/WorkingWithUs';
import Intro from '@sections/AboutUs/Intro';

const AboutUsPage = ({
    data: {
        markdownRemark: {
            frontmatter: {
                seo,
                title,
                pageHeader,
                introduction,
                workingWithUs,
                leadership,
                peopleCulture,
                certifications,
            },
        },
    },
}) => {
    const seoData = {
        title: (seo && seo.title) || title,
        description: (seo && seo.description) || '',
        image: (seo && seo.image && seo.image.publicURL) || '',
    };

    return (
        <Layout navBarExternalBg={false} disableFooterParallax={false}>
            <SEO
                title={seoData.title}
                description={seoData.description}
                image={seoData.image}
            />
            <Section>
                <PageHeader infoHeader={pageHeader} />
            </Section>
            <Intro {...introduction} />
            <WorkingWithUs data={workingWithUs} />
            <Leadership data={leadership} />
            <PeopleCulture data={peopleCulture} />
            <Certifications data={certifications} />
        </Layout>
    );
};

AboutUsPage.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const query = graphql`
    query AboutUsPage {
        markdownRemark(fields: { slug: { eq: "/pages/about-us/" } }) {
            frontmatter {
                title
                seo {
                    title
                    description
                    image {
                        publicURL
                    }
                }
                pageHeader {
                    title
                    headerDescription: description
                    image {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    previewUrl {
                        src: publicURL
                    }
                    videoUrl
                    videoCms {
                        src: publicURL
                    }
                }
                introduction {
                    leftParagraph
                    rightParagraph
                }
                workingWithUs {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                    descriptionList {
                        topic
                        overview
                        image {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                leadership {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                    teams {
                        title
                        members {
                            name
                            jobTitle
                            picture {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            hasAnimatedPicture
                        }
                    }
                }
                peopleCulture {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                    leftParagraph
                    peopleImage {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    offices {
                        title
                        locations {
                            office
                        }
                    }
                    presence {
                        title
                        cities {
                            city
                        }
                    }
                    rightSubTitle
                    rightParagraph
                }
                certifications {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                    leftParagraph
                    certificationIcons {
                        title
                        icon {
                            publicURL
                        }
                    }
                }
            }
        }
    }
`;

export default AboutUsPage;
