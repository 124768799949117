import React from 'react';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Grid, { Column, Row } from '@components/core/Grid';
import Section from '@components/core/Section';
import SectionHeader from '@components/SectionHeader';
import Tabs from '@components/core/Tabs';
import CarouselWithPadding from '@components/Carousels/CarouselWithPadding';
import PeopleCard from '@components/Cards/People';

import styles from './leadership.module.scss';

const Leadership = ({ variant, data: { sectionHeader, teams } }) => {
    const breakpoint = useBreakpoint();

    const carouselSettings = {
        slidesToShow: breakpoint.md ? 3 : 1,
        variableWidth: true,
        centerMode: false,
    };

    return (
        <Section variant={variant} className={styles.leadership}>
            <Grid>
                <Row>
                    <Column className={styles.leadershipHeaderContainer}>
                        <SectionHeader
                            eyebrow={sectionHeader.eyebrow}
                            title={sectionHeader.title}
                            summary={sectionHeader.description}
                            className={styles.sectionHeader}
                            classTitleWrapper={styles.title}
                        />
                    </Column>
                </Row>
            </Grid>
            <Tabs
                className={styles.tabsContainer}
                contentClassName={styles.tabsContent}
            >
                {teams.length > 0 &&
                    teams.map(({ title, members }) => (
                        <div className={styles.teams} key={title} label={title}>
                            {members.length > 0 && (
                                <div data-testid={title}>
                                    <CarouselWithPadding
                                        sliderClassName={styles.slider}
                                        arrowsClassName={
                                            breakpoint.md && members.length < 4
                                                ? styles.arrowsHidden
                                                : styles.arrows
                                        }
                                        arrows
                                        infinite
                                        isFullScreen
                                        settings={carouselSettings}
                                    >
                                        {members.map(
                                            ({
                                                name,
                                                jobTitle,
                                                picture,
                                                hasAnimatedPicture,
                                            }) => {
                                                return (
                                                    <PeopleCard
                                                        className={
                                                            styles.peopleCard
                                                        }
                                                        key={name}
                                                        name={name}
                                                        jobTitle={jobTitle}
                                                        picture={picture}
                                                        hasAnimatedPicture={
                                                            hasAnimatedPicture
                                                        }
                                                    />
                                                );
                                            }
                                        )}
                                    </CarouselWithPadding>
                                </div>
                            )}
                        </div>
                    ))}
            </Tabs>
        </Section>
    );
};

Leadership.propTypes = {
    variant: PropTypes.oneOf(['light', 'dark']),
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
        teams: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                members: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string,
                        jobTitle: PropTypes.string,
                        picture: PropTypes.objectOf(PropTypes.any),
                        hasAnimatedPicture: PropTypes.bool,
                    })
                ),
            })
        ),
    }).isRequired,
};

Leadership.defaultProps = {
    variant: 'light',
};

export default Leadership;
