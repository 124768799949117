import React from 'react';
import PropTypes from 'prop-types';
import Grid, { Column, Row } from '@components/core/Grid';
import Section from '@components/core/Section';
import SectionHeader from '@components/SectionHeader';
import Markdown from 'react-remarkable';
import Text from '@components/core/Text';
import Img from 'gatsby-image';
import styles from './people-culture.module.scss';

const PeopleCulture = ({ data }) => {
    const {
        sectionHeader,
        leftParagraph,
        peopleImage,
        rightSubTitle,
        rightParagraph,
        offices,
        presence,
    } = data;

    return (
        <Section className={styles.sectionPeople}>
            <Grid>
                <Row>
                    <Column md="6">
                        <SectionHeader
                            className={styles.sectionHeader}
                            eyebrow={sectionHeader.eyebrow}
                            title={sectionHeader.title}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column md="5">
                        <Text as="p" size="paragraphMd">
                            <Markdown>{leftParagraph}</Markdown>
                        </Text>
                    </Column>
                    <Column md="1" />
                    <Column md="6" className={styles.rightColumn}>
                        {peopleImage.childImageSharp && (
                            <Img
                                className={styles.imgPeople}
                                fluid={peopleImage.childImageSharp.fluid}
                            />
                        )}
                        <Row>
                            <Column col="2">
                                {offices.title && (
                                    <Text as="h5" size="h5">
                                        {offices.title}
                                    </Text>
                                )}
                                {offices.locations.length > 0 && (
                                    <ul className={styles.list}>
                                        {offices.locations.map(location => (
                                            <li
                                                className={styles.listSquare}
                                                key={location.office}
                                            >
                                                <Text as="p" size="paragraphSm">
                                                    {location.office}
                                                </Text>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </Column>
                            <Column col="2">
                                {presence.title && (
                                    <Text as="h5" size="h5">
                                        {presence.title}
                                    </Text>
                                )}
                                {presence.cities.length > 0 && (
                                    <ul className={styles.list}>
                                        {presence.cities.map(location => (
                                            <li
                                                className={styles.listSquare}
                                                key={location.city}
                                            >
                                                <Text as="p" size="paragraphSm">
                                                    {location.city}
                                                </Text>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </Column>
                        </Row>
                        <Text
                            as="h5"
                            size="h5"
                            className={styles.rightSubtitle}
                        >
                            {rightSubTitle}
                        </Text>
                        <Text as="p" size="paragraphSm">
                            <Markdown>{rightParagraph}</Markdown>
                        </Text>
                    </Column>
                </Row>
            </Grid>
        </Section>
    );
};

PeopleCulture.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
        leftParagraph: PropTypes.string.isRequired,
        peopleImage: PropTypes.shape({
            publicURL: PropTypes.string,
            childImageSharp: PropTypes.shape({
                fluid: PropTypes.objectOf(PropTypes.any),
            }),
        }),
        rightSubTitle: PropTypes.string,
        rightParagraph: PropTypes.string,
        offices: PropTypes.objectOf(PropTypes.any),
        presence: PropTypes.objectOf(PropTypes.any),
    }).isRequired,
};

export default PeopleCulture;
