import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Text from '@components/core/Text';
import styles from './tab.module.scss';

const Tab = ({ className, activeTab, label, onClick }) => {
    const [isActive, setIsActive] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const classes = cx(
        {
            [styles.tabListItem]: true,
            [styles.hover]: isHovered,
            [styles.tabListActive]: isActive,
        },
        className
    );

    const onClickHandler = () => {
        onClick(label);
    };

    useEffect(() => {
        if (activeTab === label) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [activeTab]);

    return (
        <li
            className={classes}
            onClick={onClickHandler}
            onKeyPress={onClickHandler}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            role="tab"
        >
            <Text
                as="p"
                size="paragraphXs"
                color="inherited"
                className={styles.tabText}
                normalLineHeight
            >
                {label}
            </Text>
        </li>
    );
};

Tab.propTypes = {
    className: PropTypes.string,
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

Tab.defaultProps = {
    className: '',
};

export default Tab;
