import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Section from '@components/core/Section';
import { Parallax } from 'react-scroll-parallax';
import styles from './parallax-section.module.scss';

const ParallaxSection = React.forwardRef(
    (
        {
            children,
            className,
            topColor,
            bottomColor,
            disabled,
            topDisabled,
            bottomDisabled,
            height,
            dataTheme,
            ...rest
        },
        ref
    ) => {
        const joinedClassName = cx(styles.parallaxSection, className);
        const parallaxDataTheme = dataTheme === 'light' ? 'dark' : 'light';
        const parallaxColor = dataTheme === 'light' ? '#060606' : '#fff';
        const baseStyles = {
            height,
            background: parallaxColor,
            position: 'absolute',
            width: '100%',
            left: 0,
            zIndex: 0,
        };

        return (
            <Section
                ref={ref}
                className={joinedClassName}
                dataTheme={dataTheme}
                {...rest}
            >
                {!disabled && !topDisabled && (
                    <div data-theme={parallaxDataTheme}>
                        <Parallax
                            y={[0, -100]}
                            styleInner={{
                                ...baseStyles,
                                top: 0,
                            }}
                        />
                    </div>
                )}
                {children}
                {!disabled && !bottomDisabled && (
                    <div data-theme={parallaxDataTheme}>
                        <Parallax
                            y={[100, 0]}
                            styleInner={{
                                ...baseStyles,
                                bottom: 0,
                            }}
                        />
                    </div>
                )}
            </Section>
        );
    }
);

ParallaxSection.propTypes = {
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf(['light', 'dark']),
    className: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.any),
    as: PropTypes.string,
    dataTheme: PropTypes.oneOf(['light', 'dark']),
    topColor: PropTypes.string,
    disabled: PropTypes.bool,
    bottomColor: PropTypes.string,
    topDisabled: PropTypes.bool,
    bottomDisabled: PropTypes.bool,
    height: PropTypes.string,
};

ParallaxSection.defaultProps = {
    variant: 'light',
    dataTheme: 'light',
    style: {},
    className: '',
    as: 'section',
    topColor: '#fff',
    bottomColor: '#fff',
    disabled: false,
    topDisabled: false,
    bottomDisabled: false,
    height: '200px',
};

export default ParallaxSection;
