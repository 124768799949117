import React from 'react';
import PropTypes from 'prop-types';
import slice from 'lodash/slice';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Text from '@components/core/Text';
import AnchorToSection from '@components/core/AnchorToSection';
import Grid, { Column, Row } from '@components/core/Grid';
import SectionHeader from '@components/SectionHeader';
import ArticleCard from '@components/Cards/Article';
import ParallaxSection from '@components/core/ParallaxSection';

import styles from './working-with-us.module.scss';

const WorkingWithUs = ({
    variant,
    data: { sectionHeader, descriptionList },
}) => {
    const finalDescriptionList = slice(descriptionList, 0, 3);
    const breakpoints = useBreakpoint();

    return (
        <ParallaxSection
            dataTheme={variant}
            variant={variant}
            className={styles.workingWithUs}
            disabled={!breakpoints.md}
        >
            <Grid>
                <Row>
                    <Column className={styles.workingWithUsContainer}>
                        <SectionHeader
                            variant="dark"
                            eyebrow={sectionHeader.eyebrow}
                            title={sectionHeader.title}
                            summary={sectionHeader.description}
                            className={styles.sectionHeader}
                            classTitleWrapper={styles.title}
                        />

                        <div className={styles.descriptionListContainer}>
                            {finalDescriptionList &&
                                finalDescriptionList.map(article => (
                                    <ArticleCard
                                        classCardWrapper={styles.articleItem}
                                        classImageWrapper={styles.imageArticle}
                                        classInfo={styles.info}
                                        key={article.title}
                                        article={article}
                                        colorOverview="white"
                                        sizeTitle="paragraphMd"
                                        sizeEyebrow="paragraphMd"
                                        layoutDistribution="vertical"
                                        eyebrowCase="inherit"
                                        eyebrowNormalSpacing
                                    />
                                ))}
                        </div>

                        <AnchorToSection
                            sizeIcon="4"
                            className={styles.anchorToSection}
                            toPath="/culture/"
                            animatedInHover
                        >
                            <Text
                                as="p"
                                size="paragraphSm"
                                color="white"
                                className={styles.anchorToSectionText}
                            >
                                Learn more about our culture
                            </Text>
                        </AnchorToSection>
                    </Column>
                </Row>
            </Grid>
        </ParallaxSection>
    );
};

WorkingWithUs.propTypes = {
    variant: PropTypes.oneOf(['light', 'dark']),
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
        descriptionList: PropTypes.arrayOf(
            PropTypes.shape({
                eyebrow: PropTypes.string,
                overview: PropTypes.string,
                image: PropTypes.objectOf(PropTypes.any),
            })
        ),
    }).isRequired,
};

WorkingWithUs.defaultProps = {
    variant: 'dark',
};

export default WorkingWithUs;
