/* eslint-disable import/prefer-default-export */

const BREAKPOINTS = {
    xs: 375,
    sm: 600,
    md: 1024,
    l: 1440,
    xl: 1920,
};

export { BREAKPOINTS };
